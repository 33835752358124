/*
 * @Description:
 * @Author: shufei
 * @Date: 2021-11-14 18:20:13
 * @LastEditTime: 2021-11-14 18:20:13
 * @LastEditors: shufei
 */
import empty from './index.vue'
/* istanbul ignore next */
empty.install = function (Vue) {
  Vue.component(empty.name, empty)
}
export default empty
