/*
 * @Description: 联系人相关api
 * @Author: shufei
 * @Date: 2021-11-17 15:50:23
 * @LastEditTime: 2021-11-20 14:02:06
 * @LastEditors: shufei
 */
import { get, post, service } from '@/core/request'

// 获取联系人列表
export const list = params => get(`${service.contacts}/api/contacts_list`, params)
export const add = params => post(`${service.contacts}/api/contacts_add`, params)
export const detail = params => get(`/api/activity`) // 活动详情
export const login = params => post(`/api/activity/coupon`, params) // 兑换卡券
export const goods = params => get(`/api/activity/goods`, params) // 活动商品
export const areas = params => get(`/api/areas`, params) // 地区
export const order = params => post(`/api/order`, params) // 卡券提货下单
export const orderDetail = params => get(`/api/order/${params.id}`) // 订单详情
