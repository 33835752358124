/*
 * @Description: page 模块
 * @Author: shufei
 * @Date: 2021-11-08 21:44:08
 * @LastEditTime: 2021-11-19 23:35:25
 * @LastEditors: shufei
 */
const state = {
  initLink: '',
  isLoading: null,
  sdkInfo: {}
}
const mutations = {

}
const actions = {

}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
